//import axios from "axios";
//import { adalApiFetch } from "../../adalConfig";
import {GET_USER_DETAILS} from './actionTypes';
//import loadData from "../../util/loadData";
//import { ENVIRONMENT } from '../../environment-config';

//const jsonData = loadData();
// let  xApiKey = '';
// switch(ENVIRONMENT){
//     case "prod":
//     xApiKey = jsonData.api.baseURLKeyProd;
//     break;
//     case "test":
//     xApiKey = jsonData.api.baseURLKeyTest;
//     break;
//     case "dev":
//     xApiKey = jsonData.api.baseURLKeyDev;
//     break;
//     case "lab":
//     xApiKey = jsonData.api.baseURLKeyLab;
//     break;
//     default:
//     xApiKey = jsonData.api.baseURLKeyLab;
//     break;
// }

export const receiveUserDetails = (user, accessToken, idToken) => ({ type: GET_USER_DETAILS, user, accessToken, idToken });


export const saveOktaData = (user, accessToken, idToken ) => async (dispatch) => {
    dispatch(receiveUserDetails(user, accessToken, idToken));
};