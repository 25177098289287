import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import UserAccountPopper from './UserAccountPopper';
import { connect } from "react-redux";
import { withAuth } from '@okta/okta-react';
import {saveOktaData} from '../../redux/actions/header'
//import Switch from '@material-ui/core/Switch';

const mapStateToProps = ({header}) => ({
  user: header.user,
  accessToken: header.accessToken
 // authorized: header.authorized,
 // pyramidSwitch : header.pyramidSwitch
});

const styles = theme => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    background: '#002554',
    color: '#FFF',

  },

  toolbarLogo: {
    height: '80px'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    position : 'fixed'
  },

  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    //font: 'Segoe UI Light !important',
    textAlign: 'center',
    fontFamily : ['"Segoe UI"']
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
  displayName: {
    fontFamily : ['"Segoe UI"']
  },
  colorSwitchBase: {
    color: '#FFF',
    '&$colorChecked': {
      color: '#FFF',
      '& + $colorBar': {
        backgroundColor: '#FFF',
      },
    },
  },
  colorBar: {backgroundColor: '#FFF'},
  colorChecked: {},
  switch: {
    color: 'inherit !important'
  }
});

class Header extends PureComponent {

  async saveOktaData() {
    const authenticated = this.checkAuthentication();
    if (authenticated) {
      const token = await this.props.auth.getAccessToken();
      const idToken = await this.props.auth.getIdToken();
      const user = await this.props.auth.getUser();
      this.props.saveOktaData(user, token, idToken);
    }
  }

  async checkAuthentication() {
    const authenticated = await this.props.auth.isAuthenticated();
    return authenticated;
}

  componentDidMount() {
    this.saveOktaData();
  }

  render() {

    const { classes } = this.props;

    const children = this.checkAuthentication() ?  React.Children.map(this.props.children, child => {
        return React.cloneElement(child, {
          user: this.props.user
        });
    }) : 'No Access. Please Contact IT ??.';
  
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <AppBar
            position="absolute"
            className={classNames(classes.appBar)}
          >
            <Toolbar className={classes.toolbar}>
            <img className={classes.toolbarLogo} src="it_innovation_blue.PNG" alt="" />
              <Typography
                variant="h4"
                color="inherit"
                className={classes.title}
              >
                Inbound Delivery
              </Typography>
              <UserAccountPopper {...this.props.user} />
              <IconButton color="inherit" className={classes.displayName}>
                {this.props.user.name}
              </IconButton>
            </Toolbar>
          </AppBar>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            {children}
          </main>
        </div>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {saveOktaData})(withAuth(withStyles(styles)(Header)));