import {ON_HEADER_CHANGE, ON_TABLE_MODIFIED, ON_SUBMIT_CLEAR, ON_SUBMIT, ON_SUBMIT_ERROR} from './actionTypes';
import { ENVIRONMENT } from '../../environment-config';
import loadData from  '../../util/loadData';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import copy from 'copy-to-clipboard'

const axios = require('axios');

const jsonData = loadData();

var odataURL='';
var toast_msg = '';
const alert_options = {
    onOpen: copyOnOpen,
    autoClose: false,
    draggable: false,
    closeOnClick : false,
    hideProgressBar: true,
};

switch(ENVIRONMENT){
  case "prod":
  odataURL = jsonData.api.odataURLProd;
  break;
  case "test":
  odataURL = jsonData.api.odataURLPreProd;
  break;
  case "dev":
  odataURL = jsonData.api.odataURLDev;
  break;
  case "lab":
  odataURL = jsonData.api.odataURLLab;
  break;
  default:
  odataURL = jsonData.api.odataURLLab;
  break;
}

export const onHeaderChange = (event) => (dispatch) => {
    dispatch({type: ON_HEADER_CHANGE, id: event.target.id, value: event.target.value});
}

export const onTableChange = (oldData, newData) => (dispatch, getState) => {
    if(!validateBatch(newData.lot) || !validateMaterialSku(newData.material)){
        newData = oldData
    }
    const state = getState();
    const shipments = [...state.home.shipments];
    shipments[shipments.indexOf(oldData)] = newData;
    dispatch({type: ON_TABLE_MODIFIED,  shipments })
}

export const onTableAdd = (newData) => (dispatch, getState) => {
    if(!validateBatch(newData.lot) || !validateMaterialSku(newData.material)) return
    const state = getState();
    const shipments = [...state.home.shipments];
    shipments.push(newData);
    dispatch({type: ON_TABLE_MODIFIED,  shipments })
    
}

export const onTableDelete = (oldData) => (dispatch, getState) => {
    const state = getState();
    const shipments = [...state.home.shipments];
    shipments.splice(shipments.indexOf(oldData), 1);
    dispatch({type: ON_TABLE_MODIFIED,  shipments })
}

export const onFormSubmit = () => (dispatch, getState) => {
    const user_info = JSON.parse(localStorage.getItem('okta-token-storage'))['idToken']['claims'];
    const user_name = user_info['name'];
    const user_email = user_info['preferred_username'];
    const state = getState();
    let poNum = state.home.poNum;
    if(!validatePoNumber(poNum)) return;
    if(poNum.length < 10){
        poNum = pad(poNum, 10);
    }
    const shipDate = state.home.shipDate;
    const shipments = [...state.home.shipments];
    const shipmentsToPost = [];
    dispatch({type: ON_SUBMIT });
    //Convert the packDate and expireDate for the shipments and delete the tableData
    for (var i = 0; i < shipments.length; i++) {
        const lot = shipments[i].lot;
        const material = shipments[i].material;
        const expireDate = convert_str(shipments[i].expireDate);
        const packDate = convert_str(shipments[i].packDate);
        const cases = shipments[i].cases;
        const totalLbs = shipments[i].totalLbs;
        shipmentsToPost.push({lot,material,expireDate,packDate,cases,totalLbs});
    }
    const postObj = {user_name, user_email, poNum, shipDate, shipments: shipmentsToPost}
    console.log(postObj);
    const token = state.header.accessToken;
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    console.log(odataURL);
    axios.post(odataURL,{postObj}).then(res => {
        // console.log(res.data);
        if(res.status===200){
            console.log(res.data);
            toast_msg = res.data;
            toast.dismiss();
            toast.success(res.data,alert_options);
            dispatch({type: ON_SUBMIT_CLEAR})
        }
        else if(res.status===201){
            console.log(res.data);
            toast_msg = res.data;
            toast.dismiss();
            toast.error(res.data,alert_options);
            dispatch({type: ON_SUBMIT_ERROR})
        }
      }).catch(error =>{
          console.log(error)
          toast_msg = error;
          toast.dismiss();
          toast.error(error,alert_options);
          dispatch({type: ON_SUBMIT_ERROR})
      });
}

function validateBatch(lot){
    const batchValidation = /^\d{4}[A-Z]{3}\d{2}$/;
    const valid = lot && lot.length > 0 && (lot.substring(0,9).match(batchValidation) || lot.substring(9, lot.length).length > 1);
    if(!valid){
        toast_msg = lot + ' is not a valid lot number format. e.g., 1529AAA34A';
        toast.dismiss();
        toast.error(toast_msg,alert_options);
    }
    return valid;
}

function validatePoNumber(poNum) {
    const poNumValidation = /^[0-9]*$/;
    let valid = poNum && poNum.match(poNumValidation) && poNum.length > 0 && poNum.length <= 10;
    
    if(!valid){
        toast_msg = 'PO Number is invalid.  Must be between 1-10 numeric only digits';
        toast.dismiss();
        toast.error(toast_msg,alert_options);
        // alert('PO Number is invalid.  Must be between 1-10 numeric only digits');
    }
    return valid;
}

function validateMaterialSku(sku) {
    const poNumValidation = /^[0-9]*$/;
    let valid = sku && sku.match(poNumValidation) && sku.length <= 11;
    
    if(!valid){
        toast_msg = 'Material/SKU is invalid.  Must be 11 numeric only digits';
        toast.dismiss();
        toast.error(toast_msg,alert_options);
    }
    return valid;
}

// function to convert the date string to "yyyy-mm-dd"
function convert_str(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth()+1)).slice(-2),
        day  = ("0" + date.getDate()).slice(-2);
    return [ date.getFullYear(), mnth, day ].join("-");
}

function pad(number, length) {
    var str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
    return str;
}

function copyOnOpen(){
    copy(toast_msg);
}