import React, {PureComponent} from 'react';
import { connect } from 'react-redux';
import {onHeaderChange, onFormSubmit} from '../../redux/actions/home';
import './Home.css'
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from './Table';
import Spinner from '../Spinner/Spinner';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = theme => ({

  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height:"100%",
    background: '#EDF3F9',
    marginTop: '20px'
  },
  button: {
    margin: 0,
    background: '#002554',
    align: 'right'
  },
  textField: {
    paddingRight: '20px'
  },
  instructions : {
    background : "#FFF2E0",
    marginTop: '10px'
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    fontFamily : ['"Segoe UI"']
  },
  instructionsTitle: {
    textAlign: 'left',
    fontFamily : ['"Segoe UI"']
  },
});

class Home extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container align="stretch" spacing={2}>
          <Grid item xs={12} className={classes.instructions}>
              <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.instructionsTitle}
                  >
                    Click <a href="https://inbound-delivery-training-content.s3.amazonaws.com/Inbound+Delivery+Application+Training.pdf" target="react/jsx-no-target-blank">here</a> to Access Training Material
                  </Typography>
          </Grid>
        </Grid>
        {this.props.submitted ? <Spinner/> : 
        <Paper className={classes.paper}>
          <Grid container align="stretch" spacing={2}>
            <Grid item xs={12}>
              <Typography
                  variant="h4"
                  color="inherit"
                  className={classes.title}
                >
                  Tyson Vendor Advance Shipment Notification (ASN)
                </Typography>
            </Grid> 
            <Grid item xs={2} />    
            <Grid item xs={4}>
              <TextField
                  id="poNum"
                  required
                  label="P.O. Number"
                  placeholder="P.O. Number"
                  fullWidth
                  className={classes.textField}
                  value={this.props.poNum}
                  onChange={this.props.onHeaderChange}
                />
            </Grid>
            <Grid item xs={4}>
              <TextField
                  id="shipDate"
                  required
                  label="Ship Date"
                  type="date"
                  fullWidth
                  className={classes.textField}
                  value={this.props.shipDate}
                  onChange={this.props.onHeaderChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </Grid>
            <Grid item xs={2}>
                  <Fab variant="extended"  color="primary" className={classes.button} onClick={this.props.onFormSubmit}>
                    <LocalShippingIcon className={classNames(classes.leftIcon, classes.iconLarge)} /><span style={{marginLeft:'3px'}}>Submit To Tyson</span>    
                  </Fab>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Table />
            </Grid>
          </Grid>
          <ToastContainer />
        </Paper>
        }
      </div>
    )
  }
}

const mapStateToProps = ({home}) => {
  return {
    poNum : home.poNum,
    shipDate: home.shipDate,
    alert : home.alert,
    submitted: home.submitted
  }
}

export default connect(mapStateToProps, {onHeaderChange, onFormSubmit})(withStyles(styles)(Home));